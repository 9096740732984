<template>
  <component :is="tag" class="facility-overview parent-bg">
    <div class="facility-heading">
      <h1 class="facility-name size-2xl bold" :data-facility-unique-key="mergedData.facilityUniqueKey">
        {{ mergedData.facility_title || mergedData.commercialName }}
      </h1>
      <p v-if="facilityTypeLabel" class="facility-type size-l bold">
        {{ facilityTypeLabel }}
      </p>
    </div>
    <div v-if="mergedData?.has_map" class="facility-overview-container facility-overview-with-map">
      <div class="facility-overview-map">
        <GlobalMap v-if="mergedData?.latitude && mergedData?.longitude" :blok="mergedData" />
      </div>
      <div v-if="isDesktop" class="facility-overview-picture">
        <StoryblokComponent
          v-if="mergedData?.images?.length"
          :key="mergedData?.images[0]._uid"
          :blok="mergedData?.images[0]"
          :play-disabled="true"
        />
        <SimpleButton
          v-if="mergedData?.images?.length > 1"
          class="show-all-pictures-btn"
          @click="showAllPictures(true, 0)"
          >{{ useMicroCopy("facilityoverview.pictures.label") }}</SimpleButton
        >
      </div>
    </div>
    <div v-else class="facility-overview-container">
      <div v-if="!isDesktop" class="facility-carousel">
        <GenericCarousel :items="mergedData.images" />
      </div>
      <template v-if="isDesktop">
        <div v-if="mergedData?.images?.length < 5" class="facility-overview-picture">
          <StoryblokComponent
            :key="mergedData?.images[0]._uid"
            :blok="mergedData?.images[0]"
            :play-disabled="true"
            class="picture"
          />
          <SimpleButton
            v-if="mergedData?.images?.length > 1"
            class="show-all-pictures-btn"
            @click="showAllPictures(true, 0)"
            >{{ useMicroCopy("facilityoverview.pictures.label") }}</SimpleButton
          >
        </div>
        <div v-else class="pictures-placeholder">
          <div class="facility-pictures">
            <StoryblokComponent
              v-for="(image, index) in mergedData?.images?.slice(0, 5)"
              :key="image._uid"
              :blok="image"
              :play-disabled="true"
              :size-hint="index === 0 ? 75 : 30"
              @click="showAllPictures(true, index)"
            />
            <SimpleButton
              class="show-all-pictures-btn"
              @click="showAllPictures(true, 0)"
              >{{ useMicroCopy("facilityoverview.pictures.label") }}</SimpleButton
            >
          </div>
        </div>
      </template>
    </div>
    <div class="facility-informations">
      <div class="facility-facts">
        <div>
          <p
            v-if="
              useFormattedNote(reviews?.satisfaction) && reviews?.base_rating && formattedNumberOfReviews
            "
            class="facility-note-and-reviews size-s"
          >
            <SvgIcon name="func_star" />

            <span class="note">{{ useFormattedNote(reviews?.satisfaction) }}</span
            >/{{ reviews?.base_rating }}
            <span>&nbsp;•&nbsp;</span>
            <span class="reviews bold">{{ formattedNumberOfReviews }}</span>
          </p>
          <SimpleButton 
            v-if="datasourceEntries(`overview.review.display.${mergedData.facilityTypeGroupKey}`) === 'true' && useFormattedNote(reviews?.note) && reviews?.base_rating && formattedNumberOfReviews"
            class="link facility-overview-link"
            :href="useMicroCopy(`overview.review.url.${mergedData.facilityTypeGroupKey}`)"
          >
            {{ useMicroCopy(`overview.review.label.${mergedData.facilityTypeGroupKey}`) }}
          </SimpleButton>
          <p v-if="useFormattedAddress(mergedData)" class="facility-address size-s">
            <SvgIcon name="func_location" />{{ useFormattedAddress(mergedData) }}
          </p>
          <SimpleButton
            v-if="mergedData.has_map && hasMadPolygon"
            class="link facility-overview-link"
            @click="openMapModal">
            {{ mergedData.map_cta_label ? mergedData.map_cta_label : useMicroCopy("overview.map.label.cta") }}
          </SimpleButton>
          <p
            v-if="mergedData.numberOfRoomsAvailable"
            class="facility-rooms-available size-s"
          >
            <SvgIcon name="func_bed" />{{ mergedData.numberOfRoomsAvailable }}
          </p>
          <p v-if="mergedData.roomPrice" class="facility-room-price size-s">
            <span>
              <SvgIcon name="func_tag" />
              {{ useMicroCopy("facility.overview.price.text", roomPrice) }}
            </span>
          </p>
          <VDropdown v-if="roomPriceBalloon" :distance="6" placement="top">
              <button class="info-btn">
                {{ useMicroCopy("facilityoverview.info.label") }}
              </button>
              <template #popper>
                <div v-html="roomPriceBalloon" />
              </template>
            </VDropdown>
          <p v-if="mergedData.roomPrice" class="facility-room-price-conditions">{{ useMicroCopy("facility.overview.price.mention") }}</p>
          <p v-if="datasourceEntries(`overview.price.display.${mergedData.facilityTypeGroupKey}`) === 'true'" class="facility-room-price size-s">
            <span>
              <SvgIcon name="func_tag" />
              {{ useMicroCopy(`overview.price.title.${mergedData.facilityTypeGroupKey}`) }}
            </span>
          </p>
          <SimpleButton
            v-if="datasourceEntries(`overview.price.display.${mergedData.facilityTypeGroupKey}`) === 'true'"
            class="link facility-overview-link"
            :href="useMicroCopy(`overview.price.url.${mergedData.facilityTypeGroupKey}`)"
          >
            {{ useMicroCopy(`overview.price.label.${mergedData.facilityTypeGroupKey}`) }}
          </SimpleButton>
        </div>
        <div class="additional-links">
          <SimpleButton
            v-for="link in mergedData.additional_links"
            :key="link._uuid"
            :to="`/${link.full_slug}`"
          >
            {{ link.name }}
          </SimpleButton>
        </div>
      </div>
      <div
        v-if="highLightsData?.length"
        :class="[
          'highlights',
          { 'contribuable' : hasContribuableHighlights },
          { 'max-items' : highLightsData.length == 4 },
        ]"
      >
        <div
          v-for="highlight in highLightsData.slice(0, 4)"
          :key="highlight.label"
          class="highlight size-s"
        >
          <AssetImage v-if="highlight.custom_icon?.filename" :blok="{ asset: highlight.custom_icon }" class="custom-icon" />
          <SvgIcon v-else-if="highlight.icon" class="gradient-stroke-svg" :name="highlight.icon" small />
          <p v-if="highlight.label">{{ highlight.label }}</p>
        </div>
      </div>
    </div>
    <GenericModal v-if="mergedData.has_map && mergedData.polygons" ref="mapModal" css-class="map-modal" height-auto>
      <FacilityOverviewMap :blok="mergedData" />
    </GenericModal>
    <ClientOnly>
      <Teleport to=".modals">
        <div
          v-if="showMorePictures && isDesktop"
          :class="`facility-carousel${
            showMorePictures ? ' facility-carousel-layer' : ''
          }`"
        >
          <button
            :flat="true"
            class="close-btn"
            :title="useMicroCopy('facilityoverview.hidepictures.label')"
            @click="showAllPictures(false, 0)"
          >
            <SvgIcon name="func_close" class="stroke" />
          </button>
          <GenericCarousel
            :items="mergedData.images"
            :with-thumbnails="true"
            :clicked-slide="clickedSlide"
          />
        </div>
      </Teleport>
    </ClientOnly>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  context: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok, context } = toRefs(props);

const mergedData = computed(() => ({ ...context.value, ...blok.value }));

const reviewsItem = mergedData.value.body?.find((item) => {
  return item.component === "facility-reviews";
});

const reviews = {
  numberOfReviews: mergedData?.value?.numberOfReviews,
  satisfaction: mergedData?.value?.satisfaction,
  base_rating: reviewsItem.base_rating || 5
}


const facilityTypeLabel = computed(() => {
  const type = mergedData.value.facilityTypeGroupKey;
  const formattedType = type && type.replaceAll(" ", "").toLowerCase();
  return (
    mergedData.value.website_facility_type ||
    useMicroCopy(`facility.type.${formattedType}.label`)
  );
});

const formattedNumberOfReviews = computed(() => {
  const number = reviews?.numberOfReviews;

  if (number) {
    return number
      ? `${number} ${useMicroCopy("facilityoverview.reviews.label")}`
      : "";
  } else {
    return number;
  }
});

const isDesktop = inject("isDesktop");

const hasContribuableHighlights = mergedData.value.contribuable_highlights?.length;
const hasHighlights = mergedData.value.highlights?.length;
const highLightsData = hasContribuableHighlights ?
mergedData.value.contribuable_highlights : hasHighlights ?
mergedData.value.highlights : null;

const roomPrice =  {
  price: mergedData.value.roomPrice
};

const roomPriceBalloon = useRenderRichText(mergedData.value.roomPriceBalloon)
  .replace(/^<p>/, "")
  .replace(/<\/p>$/, "");

let clickedSlide = ref(0);
let showMorePictures = ref(false);
const showAllPictures = (showIt, i) => {
  showMorePictures.value = showIt;
  clickedSlide.value = i;
};
const mapModal = ref(null);
const openMapModal = () => {
  mapModal.value && mapModal.value.openModal();
};

const config = useRuntimeConfig();
const datasourceSettingsEntries = ref(null);

datasourceSettingsEntries.value = await useCustomAsyncStoryblok("cdn/datasource_entries", {
    datasource: `${config.public["storyblokCountryPrefix"]}-country-settings`,
    cv: Date.now(),
    per_page: 1000,
  })
    .then((response) => response.value?.datasource_entries)
    .catch((error) => {
    console.log(error);
  });
const datasourceEntries = (id) => {
  return datasourceSettingsEntries.value?.find((item) => item.name === id)?.value;
};

let hasMadPolygon;
if (mergedData.value?.polygons !== undefined && mergedData.value?.polygons?.length) {
  hasMadPolygon = JSON.parse(mergedData.value?.polygons)?.filter(item => item.type === "MAD").length ? true : false;
}
</script>
<style lang="scss" scoped>
.facility-overview {
  display: grid;
  grid-template-columns: repeat($nb-columns, 1fr);
  grid-row-gap: 2rem;
  padding: 2rem 0;
  @include pair-7;

  @include for-desktop-up {
    grid-row-gap: 2.75rem;
    padding: 4rem 0 6.25rem;
  }

  .alternate & {
    @include pair-1;
  }
}
.additional-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.facility-heading {
  grid-column: 2 / -2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  @include for-tablet-landscape-up {
    gap: 1rem;
  }

  & > h1 {
    line-height: 1;
    margin: 0;
    text-align: center;
  }
}

.facility-type {
  margin-top: 0.75rem;

  @include for-desktop-up {
    margin-top: 1rem;
  }
}

.facility-overview-container {
  width: 100%;
  grid-column: 2 / -2;
  aspect-ratio: 324 / 243;

  @include for-tablet-landscape-up {
    aspect-ratio: auto;
  }

  &:not(.facility-overview-with-map) {
    .facility-overview-picture {
      @include for-tablet-landscape-up {
        aspect-ratio: 1296 / 533;
      }

      :deep(.asset-image) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.facility-overview-with-map {
  @include for-tablet-landscape-up {
    aspect-ratio: 1080 / 360;
    display: grid;
    grid-gap: 0.625rem;
    grid-template-columns: repeat(18, 1fr);
  }

  .facility-overview-map {
    @include pair-5;
    aspect-ratio: 324 / 243;
    border-radius: $radius-l;
    overflow: hidden;

    @include for-tablet-landscape-up {
      grid-column: span 8;
      aspect-ratio: auto;
    }
  }

  .facility-overview-picture {
    @include for-tablet-landscape-up {
      grid-column: span 10;
    }

    & > div {
      width: 100%;
      height: 100%;
    }

    :deep(.asset-image) {
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
    }
  }
}

.facility-overview-picture {
  @include pair-5;
  border-radius: $radius-l;
  overflow: hidden;
  height: 100%;

  .picture {
    height: 100%;
  }

  :deep(.asset-image) {
    aspect-ratio: auto;
  }
}

.pictures-placeholder {
  @include for-desktop-up {
    aspect-ratio: 1080 / 360;
  }
}

.facility-pictures {
  grid-column: 2 / -2;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(18, 1fr);

  & > div {
    grid-column: span 4;
    cursor: pointer;

    &:nth-child(1) {
      grid-column: span 10;
      grid-row: span 2;
    }

    :deep(img) {
      border-radius: $radius-l;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.facility-informations {
  grid-column: 2 / -2;
  display: grid;
  row-gap: 2rem;
  column-gap: 0;
  grid-template-columns: repeat(18, 1fr);

  @include for-desktop-up {
    grid-gap: 0.5rem;
  }

  .facility-facts {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-desktop-up {
      grid-column: 1 / -9;
    }

    .facility-overview-with-map + & {
      @include for-desktop-up {
        grid-column: 1 / -11;
      }
    }

    & > div > p {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0.5rem;

      & > svg {
        margin: 0 1rem 0 0;
        flex-shrink: 0;
      }
    }

    .facility-room-price {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        display: flex;
        align-items: center;

        & > svg {
          margin: 0 0.75rem 0 0;
        }       
      }
    }
    .facility-room-price-conditions {
      padding-left: calc(25px + 0.75rem);
      font-size: 0.875rem;
      color: var(--text-70);
      :deep(a) {
        color: var(--text-70);
      }
    }
    .v-popper--theme-dropdown {
      display: inline-block;
      vertical-align: top;
    }
  }
}
.highlights {
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  border-radius: $radius-l;
  gap: 2px;
  overflow: hidden;
  grid-column: 1 / -1;

  @include for-desktop-up {
    grid-column: -9 / -1;
    &.contribuable {
      grid-column: -11 / -1;
      }
  }

  .facility-overview-with-map + .facility-informations & {
    @include for-desktop-up {
      grid-column: -11 / -1;
    }
  }
}

.highlight {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0.75em;
  @include pair-3;
  flex: 1 0 50%;
  padding: 1rem 1.5em;
  font-size: 1.125rem;
  min-height: 5rem;

  @include for-tablet-portrait-up {
    flex: 1 0 calc(50% - 1px);
  }

  .contribuable:not(.max-items) & {
    @include for-tablet-portrait-up {
      flex-basis: 100%;
    }
  }

  .custom-icon {
    display: flex;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
  }
}

.alternate .highlight {
  @include pair-7;
}
.info-btn {
  appearance: none;
  border: none;
  background-image: url(../assets/svg/func_info.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  display: inline-block;
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;
  text-indent: 2rem;
  margin-left: 0.5em;
  cursor: pointer;
}

.show-all-pictures-btn {
  position: absolute;
  bottom: 0.87rem;
  right: 0.72rem;
}

.facility-carousel-layer {
  :deep(.swiper-slide) {
    .asset-image,
    .youtube-player {
      aspect-ratio: 1.63 !important;
      max-height: calc(100vh - 200px);
      border-radius: 1.5rem;
    }
  }
}

.facility-overview-link {
  @include size-2xs;
  display: block;
  margin: -0.5rem 0 0.75rem 2.5rem;
  padding: 0;
  border: none;
  background: transparent;
}
</style>

<style lang="scss">
.close-btn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.75rem;
  background: transparent;
  border: 0;
  padding: 0;
  grid-row: 1 / 1;
  grid-column: 2 / -2;
  justify-self: end;
  cursor: pointer;
  margin: 2rem 0 0;
}

.facility-carousel {
  &-layer {
    position: fixed;
    z-index: $z-index-modal;
    @include pair-3;
    @include inset(0);
    display: grid;
    grid-template-columns: repeat($nb-columns, 1fr);
    grid-template-rows: 1fr min-content 1fr;
    align-items: start;
  }
}
</style>
